import React, { useState } from 'react'

import useTranslation from '@hooks/useTranslation'
import { trackEventWithGA, gtagConversionAction } from '@utils'
import { GA_EVENTS_MAP } from '@constants/gaTracking'

import TryForFreeButton from '@common/buttons/TryForFreeButton'
import LanguageSwitcher from '@common/selects/LanguageSwitcher'
import { ExternalLink, LocalizedLink, LinkToInfludataApp } from '@common/links'
import { ChevronArrowIcon, InfludataLogoIcon, BurgerMenuIcon } from '@common/icons'
import {
  Header,
  HeaderWrapper,
  LogoWrapper,
  Menu,
  MenuItem,
  Actions,
  LinkToInfludataAppWrapper,
  TryForFreeButtonWrapper,
  LanguageSwitcherWrapper,
  BurgerMenuIconWrapper,
  MobileDropdown,
  MobileMenu,
  MobileMenuItem,
  ChevronArrowIconWrapper,
  PoliciesMenu,
  PoliciesMenuItem,
  MobileMenuTryForFreeButtonWrapper,
} from './styled'

const getIsActiveLink = path => {
  const isBrowser = () => typeof window !== 'undefined'
  return isBrowser() && window.location.pathname.includes(path)
}

const HeaderContainer = () => {
  const { labelStrings } = useTranslation()

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false)

  const onLoginClick = () => {
    trackEventWithGA({
      eventCategory: GA_EVENTS_MAP.categories.infludataApp,
      eventAction: GA_EVENTS_MAP.actions.signin,
    })
    gtagConversionAction()
  }

  const toggleDropdownMenu = () => setIsDropdownMenuOpen(!isDropdownMenuOpen)

  return (
    <Header>
      <HeaderWrapper>
        <LogoWrapper>
          <LocalizedLink page=''>
            <InfludataLogoIcon />
          </LocalizedLink>
        </LogoWrapper>

        <Menu>
          <MenuItem>
            <LocalizedLink page='pricing' isActive={getIsActiveLink('pricing')}>
              {labelStrings.pricingAndFeatures}
            </LocalizedLink>
          </MenuItem>

          <MenuItem>
            <LocalizedLink page='top' isActive={getIsActiveLink('top')}>
              {labelStrings.top}
            </LocalizedLink>
          </MenuItem>

          <MenuItem>
            <ExternalLink href={process.env.INFLUDATA_FAQ_HOST}>
              {labelStrings.faq}
            </ExternalLink>
          </MenuItem>
        </Menu>

        <Actions>
          <LinkToInfludataAppWrapper onClick={onLoginClick}>
            <LinkToInfludataApp path='signin' isOpenInNewTab>
              {labelStrings.login}
            </LinkToInfludataApp>
          </LinkToInfludataAppWrapper>

          <TryForFreeButtonWrapper>
            <TryForFreeButton />
          </TryForFreeButtonWrapper>

          <LanguageSwitcherWrapper>
            <LanguageSwitcher />
          </LanguageSwitcherWrapper>
        </Actions>

        <BurgerMenuIconWrapper
          onClick={toggleDropdownMenu}
          isMenuOpen={isDropdownMenuOpen}
        >
          <BurgerMenuIcon />
        </BurgerMenuIconWrapper>

        <MobileDropdown isOpen={isDropdownMenuOpen}>
          <MobileMenu>
            <MobileMenuItem>
              <LocalizedLink page='pricing'>
                {labelStrings.pricingAndFeatures}
              </LocalizedLink>

              <ChevronArrowIconWrapper>
                <ChevronArrowIcon width='20px' height='20px' />
              </ChevronArrowIconWrapper>
            </MobileMenuItem>

            <MobileMenuItem>
              <LocalizedLink page='top'>{labelStrings.top}</LocalizedLink>

              <ChevronArrowIconWrapper>
                <ChevronArrowIcon width='20px' height='20px' />
              </ChevronArrowIconWrapper>
            </MobileMenuItem>

            <MobileMenuItem>
              <ExternalLink href={process.env.INFLUDATA_FAQ_HOST}>
                {labelStrings.faq}
              </ExternalLink>

              <ChevronArrowIconWrapper>
                <ChevronArrowIcon width='20px' height='20px' />
              </ChevronArrowIconWrapper>
            </MobileMenuItem>

            <MobileMenuItem>
              <LinkToInfludataAppWrapper onClick={onLoginClick}>
                <LinkToInfludataApp path='signin' isOpenInNewTab>
                  {labelStrings.login}
                </LinkToInfludataApp>
              </LinkToInfludataAppWrapper>

              <ChevronArrowIconWrapper>
                <ChevronArrowIcon width='20px' height='20px' />
              </ChevronArrowIconWrapper>
            </MobileMenuItem>
          </MobileMenu>

          <PoliciesMenu>
            <PoliciesMenuItem>
              <LocalizedLink page='impressum'>{labelStrings.imprint}</LocalizedLink>
            </PoliciesMenuItem>

            <PoliciesMenuItem>
              <LocalizedLink page='privacy'>{labelStrings.privacyPolicy}</LocalizedLink>
            </PoliciesMenuItem>

            <PoliciesMenuItem>
              <LocalizedLink page='terms'>{labelStrings.termAndConditions}</LocalizedLink>
            </PoliciesMenuItem>
          </PoliciesMenu>

          <MobileMenuTryForFreeButtonWrapper>
            <TryForFreeButton />
          </MobileMenuTryForFreeButtonWrapper>
        </MobileDropdown>
      </HeaderWrapper>
    </Header>
  )
}

export default HeaderContainer
