import React from 'react'

import useTranslation from '@hooks/useTranslation'
import availableLangs from '@localization/availableLangs'

import { LanguageItems, LanguageItem } from './styled'

const LanguageSwitcher = () => {
  const { currentLang } = useTranslation()

  const languageOptions = [
    { value: availableLangs.en, label: 'EN' },
    { value: availableLangs.de, label: 'DE' },
  ]

  if (typeof window === 'undefined') return null

  return (
    <LanguageItems>
      {languageOptions.map(languageItem => {
        const getPath = () => {
          const pathnameArr = window.location.pathname
            .split('/')
            .filter(item => item !== '')

          if (currentLang === availableLangs.en) {
            if (languageItem.value !== availableLangs.en) {
              pathnameArr.unshift(languageItem.value)
            }
          } else if (languageItem.value !== availableLangs.en) {
            pathnameArr[0] = languageItem.value
          } else {
            pathnameArr.shift()
          }

          if (!pathnameArr.length) return '/'
          return `/${pathnameArr.join('/')}/`
        }

        const isDisabled = languageItem.value === currentLang

        return (
          <LanguageItem
            to={getPath()}
            label={languageItem.label}
            key={languageItem.value}
            isDisabled={isDisabled}
          >
            {languageItem.label}
          </LanguageItem>
        )
      })}
    </LanguageItems>
  )
}

export default LanguageSwitcher
