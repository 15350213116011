import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '@hooks/useTranslation'
import availableLangs from '@localization/availableLangs'
import { StyledLocalizedLink } from './styled'

const LocalizedLink = ({ page, isActive, children }) => {
  const { currentLang } = useTranslation()
  const pagePath = page ? `${page}/` : ''
  const to =
    currentLang !== availableLangs.en ? `/${currentLang}/${pagePath}` : `/${pagePath}`

  return (
    <StyledLocalizedLink to={to} isActive={isActive} className='localized-link'>
      {children}
    </StyledLocalizedLink>
  )
}

LocalizedLink.propTypes = {
  page: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.node,
}

LocalizedLink.defaultProps = {
  isActive: false,
  children: null,
}

export default LocalizedLink
