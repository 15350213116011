import React from 'react'
import PropTypes from 'prop-types'
import { StyledExternalLink } from './styled'

const ExternalLink = ({ href, children, ...linkProps }) => {
  return (
    <StyledExternalLink
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      className='external-link'
      {...linkProps}
    >
      {children}
    </StyledExternalLink>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
}

ExternalLink.defaultProps = {
  children: null,
}

export default ExternalLink
