import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export const LanguageItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const LanguageItem = styled(({ isDisabled, label, ...props }) => <Link {...props} />)`
  font-family: ${props => props.theme.fontFamily.inter};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 7px;
  color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.white};
  border-radius: 6px;

  ${props => {
    return props.isDisabled &&
    `
    opacity: 0.6;
    pointer-events: none;
  `
  }}

  & + & {
    margin-left: 10px;
  }

  &:hover {
    color: ${props => props.theme.color.white};

    ${props =>
      !props.isDisabled &&
      `
      cursor: pointer;
      color: ${props.theme.color.lightBlack} !important;
      background-color: ${props.theme.color.white};
    `}
  }
`
