import React from 'react'
import useTranslation from '@hooks/useTranslation'
import TryForFreeButton from '@common/buttons/TryForFreeButton'
import LanguageSwitcher from '@common/selects/LanguageSwitcher'
import { LocalizedLink } from '@common/links'
import {
  Footer,
  FooterWrapper,
  Copyright,
  FooterMenu,
  TryForFreeButtonWrapper,
  LanguageSwitcherWrapper,
  Flex,
} from './styled'

const FooterContainer = () => {
  const { labelStrings } = useTranslation()

  return (
    <Footer>
      <FooterWrapper>
        <Copyright>{labelStrings.copyright} 2021 // {labelStrings.byOnlinePunks}</Copyright>

        <FooterMenu>
          <LocalizedLink page="impressum">{labelStrings.imprint}</LocalizedLink>
          <LocalizedLink page="privacy">{labelStrings.privacyPolicy}</LocalizedLink>
          <LocalizedLink page="terms">{labelStrings.termAndConditions}</LocalizedLink>
        </FooterMenu>

        <Flex>
          <TryForFreeButtonWrapper>
            <TryForFreeButton />
          </TryForFreeButtonWrapper>

          <LanguageSwitcherWrapper>
            <LanguageSwitcher />
          </LanguageSwitcherWrapper>
        </Flex>
      </FooterWrapper>
    </Footer>
  )
}

export default FooterContainer
