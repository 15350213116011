import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import { StyledLinkToInfludataApp } from './styled'

const LinkToInfludataApp = ({ path, query, isOpenInNewTab, children }) => {
  const basePath = process.env.INFLUDATA_APP_HOST
  const stringifiedQuery = queryString.stringify(query)
  const href = `${basePath}/${path}${stringifiedQuery ? `?${stringifiedQuery}` : ''}`

  return (
    <StyledLinkToInfludataApp
      href={href}
      className="link-to-infludata-app"
      {...(isOpenInNewTab && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
    >
      {children}
    </StyledLinkToInfludataApp>
  )
}

LinkToInfludataApp.propTypes = {
  path: PropTypes.string,
  query: PropTypes.object,
  isOpenInNewTab: PropTypes.bool,
  children: PropTypes.node,
}

LinkToInfludataApp.defaultProps = {
  path: '',
  query: {},
  isOpenInNewTab: false,
  children: null,
}

export default LinkToInfludataApp
