import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

export const Footer = styled.footer`
  background: ${props => props.theme.infludataMainGradient};
  padding: 25px;
`

export const FooterWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  a:hover {
    color: ${props => props.theme.color.white};
  }

  ${props => props.theme.media.lessThan('950px')`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  `}
`

export const Copyright = styled.div`
  text-align: center;
`

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 15px;

  .localized-link {
    padding: 0;
    margin-right: 20px;
    white-space: nowrap;
    text-transform: none;
  }

  ${props => props.theme.media.lessThan('1300px')`
    margin-left: 0;
  `}

  ${props => props.theme.media.lessThan('950px')`
    margin: 15px 0 15px;
  `}

  ${props => props.theme.media.lessThan('530px')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .localized-link {
      padding: 0

      & + & {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  `}
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`

export const TryForFreeButtonWrapper = styled.div`
  margin-right: 20px;
`

export const LanguageSwitcherWrapper = styled.div``
