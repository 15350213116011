import React from 'react'
import { Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import useTranslation from '@hooks/useTranslation'

const CookiesConsent = () => {
  const { labelStrings, currentLang } = useTranslation()
  return (
    <CookieConsent enableDeclineButton>
      {labelStrings.cookieMsg}{' '}
      <Link to={`/${currentLang}/privacy`}>
        {labelStrings.privacyPolicy}
      </Link>
      <Link to={`/${currentLang}/terms`}>
        {labelStrings.termAndConditions}
      </Link>
    </CookieConsent>
  )
}

export default CookiesConsent
