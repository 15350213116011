import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const activeLinkStyles = css`
  background-color: ${props => props.theme.color.infludataYellow};
  font-weight: bold;
`

const hoverLinkStyles = css`
  opacity: 0.8;
`

const linkStyles = css`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-radius: 13px;
  font-family: ${props => props.theme.fontFamily.inter};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.color.white};

  ${props => props.theme.media.lessThan('mid')`
    padding: 10px 0;
  `}

  ${props => props.isActive && activeLinkStyles}

  ${props =>
    !props.isActive &&
    `
    &:hover {
      ${hoverLinkStyles}
    }
  `}
`

export const StyledLocalizedLink = styled(({ isActive, ...props }) => (
  <Link {...props} />
))`
  ${linkStyles}
`

export const StyledExternalLink = styled.a`
  ${linkStyles}
`

export const StyledLinkToInfludataApp = styled.a`
  ${linkStyles}
`
