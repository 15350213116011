import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '@hooks/useTranslation'
import { LinkToInfludataApp } from '@common/links'

const StyledButton = styled.button`
  padding: 8px 20px;
  border-radius: 9px;
  background-color: ${props => props.theme.color.white};

  &:focus {
    outline: none;
  }
`

const LinkToInfludataAppWrapper = styled.div`
  .link-to-infludata-app {
    padding: 0;
  }
`

const ButtonText = styled.span`
  font-family: ${props => props.theme.fontFamily.inter};
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  ${props => props.theme.getTextGradientStyles()}
`

const TryForFreeButton = ({ label }) => {
  const { labelStrings } = useTranslation()

  const googleAnalyticsQuery = useSelector(state => state.googleAnalyticsQuery)

  return (
    <LinkToInfludataAppWrapper>
      <LinkToInfludataApp path='signup' query={googleAnalyticsQuery} isOpenInNewTab>
        <StyledButton type='button' onClick={null}>
          <ButtonText>{label || labelStrings.tryForFree}</ButtonText>
        </StyledButton>
      </LinkToInfludataApp>
    </LinkToInfludataAppWrapper>
  )
}

TryForFreeButton.propTypes = {
  label: PropTypes.string,
}

TryForFreeButton.defaultProps = {
  label: '',
}

export default TryForFreeButton
