import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Icon = styled.span`
    margin: ${props => (props.tabIcon ? 0 : 6)}px;
    cursor: pointer;
    svg {
      margin-bottom: 1.5px;
      path {
        fill: ${props => {
          if (props.isActiveIcon) {
            if (props.tabIcon) {
              return props.inFocus
                ? props.theme.pickColorDependOnPlatform(props.userindex).primary
                : props.theme.color.darkGrey
            }
            return props.theme.pickColorDependOnPlatform(props.userindex).primary
          }
          if (props.isWhite) return props.theme.color.white
          return props.isGrey ? props.theme.color.grey : props.theme.color.darkGrey
        }};
      }
    }

    &:hover {
      svg {
        path {
          fill: ${props => {
            if (props.isWhite) return props.theme.color.white
            if (props.tabIcon) {
              return props.inFocus
                ? props.theme.pickColorDependOnPlatform(props.userindex).light
                : // : props.theme.color.darkGrey
                props.isGrey
                ? props.theme.color.grey
                : props.theme.color.darkGrey
            }
            return props.theme.pickColorDependOnPlatform(props.userindex).light
          }};
        }
      }
  `

const IconContainer = ({
  isActiveIcon,
  tabIcon,
  isWhite,
  isGrey,
  inFocus,
  userindex,
  children,
}) => {
  const isActive = isActiveIcon === undefined ? true : isActiveIcon
  return (
    <Icon
      isActiveIcon={isActive}
      tabIcon={tabIcon}
      isWhite={isWhite}
      isGrey={isGrey}
      userindex={userindex}
      inFocus={inFocus}
    >
      {children}
    </Icon>
  )
}

IconContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isActiveIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(undefined)]),
  tabIcon: PropTypes.bool,
  isWhite: PropTypes.bool,
  isGrey: PropTypes.bool,
  inFocus: PropTypes.bool,
  userindex: PropTypes.string,
}
IconContainer.defaultProps = {
  isActiveIcon: undefined,
  tabIcon: false,
  isWhite: false,
  isGrey: false,
  inFocus: false,
  userindex: '',
}

export default IconContainer
