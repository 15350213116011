import { EMAIL_VALID_REGEX, PHONE_VALID_REGEX } from '@constants/login'

export const delayFunc = (func, time = 200) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), time)
  }).then(() => func())
}

export const checkEmailValidation = email => {
  if (email.includes('+')) return false
  const EMAIL_VALID_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return EMAIL_VALID_REGEX.test(String(email).toLowerCase())
}

export const trackEventWithGA = ({ eventCategory, eventAction }) => {
  if (window && window.ga) {
    window.ga('send', 'event', eventCategory, eventAction)
  }
}

export const gtagConversionAction = () => {
  if (window && window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-708812696/ohTyCMrs-OABEJi__tEC',
    })
  }
}

export function prettifyBigNum(value, roundVal = 0) {
  if (value > 1000000000 || value < -1000000000) {
    return `${(value / 1000000000).toFixed(roundVal).toLocaleString()}b`
  }
  if (value > 1000000 || value < -1000000) {
    return `${(value / 1000000).toFixed(roundVal).toLocaleString()}m`
  }
  if (value > 1000 || value < -1000) {
    return `${(value / 1000).toFixed(roundVal).toLocaleString()}k`
  }
  return value ? value.toFixed(roundVal) : '0'
}

export const checkIsUrlWithHttp = url => {
  return url.startsWith('http://') || url.startsWith('https://')
}

export const constructLinkToWECFileServer = (path = '') => {
  return path
    ? `https://img.infludata.com${path.startsWith('/') ? path : `/${path}`}`
    : ''
}

export const getLinkToUserImage = imgLink => {
  if (!imgLink || typeof imgLink !== 'string') return null
  return checkIsUrlWithHttp(imgLink) ? imgLink : constructLinkToWECFileServer(imgLink)
}

export const validateEmail = email => {
  if (email.includes('+')) return false
  return EMAIL_VALID_REGEX.test(String(email).toLowerCase())
}

export const validatePhone = phone => {
  const preparedString = phone.trim()
  const tested = PHONE_VALID_REGEX.test(preparedString)
  return tested
}

export const validatePassword = ({ password, minLetters = 6, withUpperCase = true }) => {
  if (!password) return false
  if (password.length < minLetters) return false // less than minLetters
  if (withUpperCase && password.toLowerCase() === password) return false // no big letters
  return true
}

export const generateRandomID = () =>
  `_${Math.random()
    .toString(36)
    .substr(2, 9)}`

export const chunksArray = (array = [], n = 1) => {
  return Array(Math.ceil(array?.length / n))
    .fill()
    .map((_, i) => array.slice(i * n, i * n + n))
}

export const convertHexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
