export const GA_EVENTS_MAP = {
  categories: {
    infludataApp: 'InfludataApp',
  },
  actions: {
    signin: 'signin',
    signup: 'signup',
    findCreator: 'findcreator',
  },
}
