import styled from 'styled-components'

export const Header = styled.header`
  position: sticky;
  top: 0px;
  background: ${props => props.theme.infludataMainGradient};
  padding: 15px 25px;
  z-index: 1;
`

export const HeaderWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  a:hover {
    color: ${props => props.theme.color.white};
  }
`

export const LogoWrapper = styled.div`
  margin-right: 30px;

  .localized-link {
    padding: 0;
  }

  ${props => props.theme.media.lessThan('mobile')`
    margin-right: 10px;
  `}

  ${props => props.theme.media.lessThan('350px')`
    svg {
      width: 130px;
    }
  `}
`

export const Menu = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  ${props => props.theme.media.lessThan('mid')`
    display: none;
    align-items: flex-start;
  `}
`

export const MenuItem = styled.div`
  margin-right: 30px;
`

export const TryForFreeButtonWrapper = styled.div`
  ${props => props.theme.media.lessThan('550px')`
    display: none;
  `}
`

export const LinkToInfludataAppWrapper = styled.div``

export const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;

  ${props => props.theme.media.lessThan('mid')`
    ${LinkToInfludataAppWrapper} {
      display: none;
    }
  `}

  ${props => props.theme.media.lessThan('600px')`
    ${TryForFreeButtonWrapper} {
      display: none;
    }
  `}
`

export const LanguageSwitcherWrapper = styled.div`
  margin-left: 20px;
`

export const BurgerMenuIconWrapper = styled.div`
  margin-left: 20px;
  display: none;
  transform: none;
  ${props =>
    props.isMenuOpen &&
    `
    transform: rotate(-90deg);
  `}
  transition: transform .3s ease;

  &:hover {
    cursor: pointer;
  }

  ${props => props.theme.media.lessThan('mid')`
    display: block;
  `}
`

export const MobileDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0 20px 20px 20px;
  background: ${props => props.theme.infludataMainGradient};
  border-top: 2px solid ${props => props.theme.color.infludataOffWhite};
  border-bottom: 2px solid ${props => props.theme.color.infludataOffWhite};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};

  @media screen and (min-width: 901px) {
    display: none;
  }
`

export const MobileMenu = styled.div``

export const MobileMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid ${props => props.theme.color.white};

  a,
  ${LinkToInfludataAppWrapper} {
    width: 100%;
    justify-content: flex-start;
  }

  ${LinkToInfludataAppWrapper} a {
    justify-content: flex-start;
  }
`

export const ChevronArrowIconWrapper = styled.div`
  transform: rotate(90deg);
`

export const PoliciesMenu = styled.div`
  padding: 10px 5px;
`

export const PoliciesMenuItem = styled.div`
  a {
    text-transform: none;
    justify-content: flex-start;
    padding: 5px 0;
  }
`
export const MobileMenuTryForFreeButtonWrapper = styled.div`
  display: block;

  @media screen and (min-width: 601px) {
    display: none;
  }
`
