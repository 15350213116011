import React from 'react'
import styled from 'styled-components'
import IconsCommonContainer from './IconsCommonContainer'

const IconWrapper = styled.span`
  cursor: pointer;
  & svg {
    fill: ${props => props.fillColor || props.theme.color.white};
  }
`

export const InfludataLogoIcon = ({ height = '38px', width = '163px', ...props }) => {
  return (
    <IconWrapper {...props}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 126 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
      <path
        d="M71.868 6.64009V0.0900879H72.618V2.51009H72.678C72.738 2.42009 72.808 2.32009 72.908 2.18009C73.008 2.05009 73.148 1.93009 73.338 1.82009C73.528 1.71009 73.778 1.66009 74.098 1.66009C74.508 1.66009 74.878 1.76009 75.188 1.97009C75.498 2.18009 75.748 2.47009 75.928 2.85009C76.108 3.23009 76.198 3.68009 76.198 4.19009C76.198 4.71009 76.108 5.16009 75.928 5.54009C75.748 5.92009 75.508 6.21009 75.188 6.42009C74.878 6.63009 74.518 6.73009 74.108 6.73009C73.788 6.73009 73.538 6.68009 73.348 6.57009C73.158 6.46009 73.008 6.34009 72.908 6.21009C72.808 6.07009 72.728 5.96009 72.668 5.87009H72.578V6.62009H71.868V6.64009ZM72.608 4.18009C72.608 4.55009 72.658 4.88009 72.768 5.16009C72.878 5.44009 73.038 5.66009 73.248 5.82009C73.458 5.98009 73.708 6.06009 74.018 6.06009C74.338 6.06009 74.598 5.98009 74.808 5.81009C75.018 5.64009 75.178 5.42009 75.288 5.13009C75.398 4.85009 75.448 4.53009 75.448 4.18009C75.448 3.83009 75.398 3.52009 75.288 3.24009C75.178 2.97009 75.018 2.75009 74.808 2.59009C74.598 2.43009 74.328 2.34009 74.008 2.34009C73.698 2.34009 73.448 2.42009 73.238 2.57009C73.028 2.72009 72.868 2.94009 72.768 3.22009C72.658 3.50009 72.608 3.82009 72.608 4.18009Z"
        fill="white"
      />
      <path
        d="M77.658 8.47999C77.528 8.47999 77.418 8.46999 77.318 8.44999C77.218 8.42999 77.148 8.40999 77.108 8.38999L77.298 7.72999C77.478 7.77999 77.648 7.78999 77.788 7.77999C77.928 7.76999 78.058 7.69999 78.168 7.58999C78.278 7.47999 78.378 7.29999 78.468 7.04999L78.608 6.66999L76.788 1.73999H77.608L78.958 5.64999H79.008L80.358 1.73999L81.178 1.74999L79.088 7.35999C79.008 7.60999 78.888 7.81999 78.748 7.98999C78.608 8.15999 78.448 8.27999 78.268 8.35999C78.088 8.43999 77.888 8.47999 77.658 8.47999Z"
        fill="white"
      />
      <path
        d="M85.828 6.63998L84.328 1.72998H85.118L86.178 5.48998H86.228L87.278 1.72998H88.088L89.128 5.47998H89.178L90.238 1.72998H91.028L89.528 6.63998H88.788L87.718 2.86998H87.638L86.568 6.63998H85.828Z"
        fill="white"
      />
      <path
        d="M93.928 6.73992C93.458 6.73992 93.048 6.62992 92.708 6.41992C92.368 6.20992 92.108 5.91992 91.918 5.53992C91.738 5.15992 91.638 4.71992 91.638 4.21992C91.638 3.71992 91.728 3.26992 91.918 2.88992C92.098 2.50992 92.358 2.19992 92.688 1.98992C93.018 1.76992 93.408 1.66992 93.848 1.66992C94.108 1.66992 94.358 1.70992 94.608 1.79992C94.858 1.87992 95.088 2.01992 95.288 2.20992C95.488 2.39992 95.658 2.64992 95.778 2.96992C95.898 3.27992 95.958 3.66992 95.958 4.12992V4.44992H92.178V3.78992H95.198C95.198 3.50992 95.148 3.26992 95.038 3.04992C94.928 2.82992 94.768 2.65992 94.568 2.53992C94.368 2.40992 94.128 2.34992 93.858 2.34992C93.558 2.34992 93.298 2.41992 93.078 2.56992C92.858 2.71992 92.688 2.90992 92.578 3.14992C92.458 3.38992 92.398 3.63992 92.398 3.90992V4.33992C92.398 4.70992 92.458 5.01992 92.588 5.27992C92.718 5.53992 92.898 5.72992 93.128 5.85992C93.358 5.98992 93.628 6.05992 93.928 6.05992C94.128 6.05992 94.308 6.02992 94.468 5.97992C94.628 5.91992 94.768 5.83992 94.888 5.71992C95.008 5.60992 95.098 5.45992 95.158 5.28992L95.888 5.49992C95.808 5.74992 95.678 5.95992 95.498 6.14992C95.318 6.33992 95.098 6.47992 94.828 6.57992C94.558 6.68992 94.258 6.73992 93.928 6.73992Z"
        fill="white"
      />
      <path
        d="M102.478 2.14H101.688C101.638 1.91 101.558 1.71 101.448 1.54C101.328 1.37 101.188 1.22 101.028 1.1C100.868 0.98 100.678 0.89 100.478 0.83C100.278 0.77 100.068 0.74 99.848 0.74C99.448 0.74 99.098 0.84 98.768 1.04C98.438 1.24 98.188 1.54 98.008 1.92C97.818 2.31 97.728 2.79 97.728 3.36C97.728 3.93 97.818 4.4 98.008 4.79C98.198 5.18 98.448 5.48 98.768 5.68C99.088 5.88 99.448 5.99 99.848 5.99C100.068 5.99 100.278 5.96 100.478 5.9C100.678 5.84 100.858 5.75 101.028 5.63C101.198 5.51 101.338 5.37 101.448 5.19C101.558 5.01 101.648 4.82 101.688 4.59H102.478C102.418 4.92 102.308 5.22 102.148 5.49C101.988 5.75 101.798 5.98 101.558 6.16C101.318 6.34 101.058 6.48 100.768 6.58C100.478 6.68 100.168 6.72 99.838 6.72C99.278 6.72 98.788 6.58 98.348 6.31C97.908 6.04 97.568 5.65 97.318 5.15C97.068 4.65 96.948 4.05 96.948 3.36C96.948 2.67 97.068 2.07 97.318 1.57C97.568 1.07 97.908 0.68 98.348 0.41C98.778 0.14 99.278 0 99.838 0C100.168 0 100.478 0.0500001 100.768 0.14C101.058 0.24 101.318 0.38 101.558 0.56C101.788 0.74 101.988 0.97 102.148 1.23C102.308 1.5 102.418 1.8 102.478 2.14Z"
        fill="white"
      />
      <path
        d="M103.648 6.6399V1.7299H104.378V2.4699H104.428C104.518 2.2299 104.678 2.0299 104.918 1.8799C105.158 1.7299 105.418 1.6499 105.708 1.6499C105.768 1.6499 105.828 1.6499 105.918 1.6499C105.998 1.6499 106.068 1.6499 106.108 1.6599V2.4299C106.078 2.4199 106.028 2.4099 105.938 2.3999C105.848 2.3899 105.748 2.3799 105.648 2.3799C105.408 2.3799 105.198 2.4299 105.008 2.5299C104.818 2.6299 104.678 2.7699 104.568 2.9399C104.458 3.1099 104.408 3.3099 104.408 3.5299V6.6399H103.648Z"
        fill="white"
      />
      <path
        d="M108.908 6.73992C108.438 6.73992 108.028 6.62992 107.688 6.41992C107.348 6.20992 107.088 5.91992 106.898 5.53992C106.718 5.15992 106.618 4.71992 106.618 4.21992C106.618 3.71992 106.708 3.26992 106.898 2.88992C107.078 2.50992 107.338 2.19992 107.668 1.98992C107.998 1.76992 108.388 1.66992 108.828 1.66992C109.088 1.66992 109.338 1.70992 109.588 1.79992C109.838 1.87992 110.068 2.01992 110.268 2.20992C110.468 2.39992 110.638 2.64992 110.758 2.96992C110.878 3.27992 110.938 3.66992 110.938 4.12992V4.44992H107.158V3.78992H110.178C110.178 3.50992 110.128 3.26992 110.018 3.04992C109.908 2.82992 109.748 2.65992 109.548 2.53992C109.348 2.40992 109.108 2.34992 108.838 2.34992C108.538 2.34992 108.278 2.41992 108.058 2.56992C107.838 2.71992 107.668 2.90992 107.558 3.14992C107.438 3.38992 107.378 3.63992 107.378 3.90992V4.33992C107.378 4.70992 107.438 5.01992 107.568 5.27992C107.698 5.53992 107.878 5.72992 108.108 5.85992C108.338 5.98992 108.608 6.05992 108.908 6.05992C109.108 6.05992 109.288 6.02992 109.448 5.97992C109.608 5.91992 109.748 5.83992 109.868 5.71992C109.988 5.60992 110.078 5.45992 110.138 5.28992L110.868 5.49992C110.788 5.74992 110.658 5.95992 110.478 6.14992C110.298 6.33992 110.078 6.47992 109.808 6.57992C109.538 6.68992 109.238 6.73992 108.908 6.73992Z"
        fill="white"
      />
      <path
        d="M113.538 6.74992C113.228 6.74992 112.948 6.68992 112.688 6.56992C112.438 6.44992 112.228 6.27992 112.088 6.05992C111.938 5.83992 111.868 5.55992 111.868 5.23992C111.868 4.95992 111.928 4.72992 112.038 4.54992C112.148 4.36992 112.298 4.23992 112.478 4.13992C112.668 4.03992 112.868 3.96992 113.098 3.91992C113.318 3.86992 113.548 3.82992 113.778 3.79992C114.078 3.75992 114.318 3.72992 114.508 3.70992C114.698 3.68992 114.828 3.65992 114.918 3.60992C115.008 3.55992 115.048 3.47992 115.048 3.35992V3.32992C115.048 3.01992 114.958 2.76992 114.788 2.59992C114.618 2.42992 114.358 2.33992 114.008 2.33992C113.648 2.33992 113.368 2.41992 113.158 2.57992C112.958 2.73992 112.808 2.90992 112.728 3.07992L112.008 2.81992C112.138 2.51992 112.308 2.28992 112.518 2.11992C112.728 1.94992 112.968 1.82992 113.218 1.76992C113.468 1.69992 113.728 1.66992 113.968 1.66992C114.128 1.66992 114.308 1.68992 114.508 1.72992C114.718 1.76992 114.908 1.83992 115.108 1.95992C115.298 2.07992 115.458 2.24992 115.588 2.47992C115.718 2.70992 115.778 3.01992 115.778 3.40992V6.63992H115.028V5.96992H114.988C114.938 6.07992 114.848 6.18992 114.728 6.30992C114.608 6.42992 114.448 6.53992 114.248 6.61992C114.048 6.69992 113.818 6.74992 113.538 6.74992ZM113.648 6.07992C113.948 6.07992 114.198 6.01992 114.408 5.89992C114.618 5.77992 114.768 5.62992 114.878 5.44992C114.988 5.26992 115.038 5.06992 115.038 4.86992V4.16992C115.008 4.20992 114.938 4.23992 114.828 4.26992C114.718 4.29992 114.598 4.32992 114.458 4.34992C114.318 4.36992 114.188 4.38992 114.048 4.40992C113.918 4.42992 113.808 4.43992 113.728 4.44992C113.528 4.47992 113.348 4.51992 113.178 4.56992C113.008 4.62992 112.868 4.70992 112.768 4.81992C112.668 4.92992 112.608 5.07992 112.608 5.27992C112.608 5.53992 112.708 5.73992 112.898 5.86992C113.098 6.00992 113.348 6.07992 113.648 6.07992Z"
        fill="white"
      />
      <path
        d="M119.298 1.73005V2.37005H116.758V1.73005H119.298ZM117.498 0.550049H118.248V5.23005C118.248 5.44005 118.278 5.60005 118.338 5.71005C118.398 5.82005 118.478 5.89005 118.578 5.92005C118.678 5.95005 118.778 5.97005 118.888 5.97005C118.968 5.97005 119.038 5.97005 119.088 5.96005C119.138 5.95005 119.188 5.94005 119.218 5.94005L119.368 6.62005C119.318 6.64005 119.248 6.66005 119.158 6.68005C119.068 6.70005 118.948 6.71005 118.808 6.71005C118.598 6.71005 118.388 6.67005 118.188 6.57005C117.988 6.48005 117.818 6.34005 117.688 6.15005C117.558 5.96005 117.488 5.73005 117.488 5.44005V0.550049H117.498Z"
        fill="white"
      />
      <path
        d="M122.448 6.73992C121.978 6.73992 121.568 6.62992 121.228 6.41992C120.888 6.20992 120.628 5.91992 120.438 5.53992C120.258 5.15992 120.158 4.71992 120.158 4.21992C120.158 3.71992 120.248 3.26992 120.438 2.88992C120.618 2.50992 120.878 2.19992 121.208 1.98992C121.538 1.76992 121.928 1.66992 122.368 1.66992C122.628 1.66992 122.878 1.70992 123.128 1.79992C123.378 1.87992 123.608 2.01992 123.808 2.20992C124.008 2.39992 124.178 2.64992 124.298 2.96992C124.418 3.27992 124.478 3.66992 124.478 4.12992V4.44992H120.698V3.78992H123.718C123.718 3.50992 123.668 3.26992 123.558 3.04992C123.448 2.82992 123.288 2.65992 123.088 2.53992C122.888 2.40992 122.648 2.34992 122.378 2.34992C122.078 2.34992 121.818 2.41992 121.598 2.56992C121.378 2.71992 121.208 2.90992 121.098 3.14992C120.978 3.38992 120.918 3.63992 120.918 3.90992V4.33992C120.918 4.70992 120.978 5.01992 121.108 5.27992C121.238 5.53992 121.418 5.72992 121.648 5.85992C121.878 5.98992 122.148 6.05992 122.448 6.05992C122.648 6.05992 122.828 6.02992 122.988 5.97992C123.148 5.91992 123.288 5.83992 123.408 5.71992C123.528 5.60992 123.618 5.45992 123.678 5.28992L124.408 5.49992C124.328 5.74992 124.198 5.95992 124.018 6.14992C123.838 6.33992 123.618 6.47992 123.348 6.57992C123.078 6.68992 122.778 6.73992 122.448 6.73992Z"
        fill="white"
      />
      <path
        d="M93.15 20.7659H89.96V17.5659H79.31V31.4059H96.34V27.1459H93.15V20.7659ZM95.28 28.2159V30.3459H80.38V18.6359H88.9V20.7659H82.51V28.2159H89.96V21.8259H92.09V28.2159H95.28ZM88.89 21.8259V27.1459H83.57V21.8259H88.89Z"
        fill="white"
      />
      <path
        d="M121.89 27.1459V20.7659H118.7V17.5659H108.06V31.4059H125.09V27.1459H121.89ZM124.02 30.3459H109.12V18.6359H117.64V20.7659H111.25V28.2159H118.7V21.8259H120.83V28.2159H124.02V30.3459ZM117.63 21.8259V27.1459H112.31V21.8259H117.63Z"
        fill="white"
      />
      <path
        d="M102.73 14.376H98.47V31.406H102.73V21.826H105.92V17.566H102.73V14.376ZM104.86 18.636V20.766H101.67V30.346H99.54V15.446H101.67V18.636H104.86Z"
        fill="white"
      />
      <path
        d="M73.99 11.176H56.95V31.406H73.98V28.216H77.17V14.376H73.98V11.176H73.99ZM72.92 30.346H58.02V12.246H72.92V14.376H60.15V28.216H72.92V30.346ZM72.92 15.446V27.156H61.21V15.436L72.92 15.446ZM76.12 15.446V27.156H73.99V15.436L76.12 15.446Z"
        fill="white"
      />
      <path
        d="M3.19 14.906H0V18.096H3.19V14.906Z"
        fill="white"
      />
      <path
        d="M3.19 21.2961H0V30.8761H3.19V21.2961Z"
        fill="white"
      />
      <path
        d="M6.39001 18.0959V30.8759H9.58001V21.2959H15.97V30.8759H19.16V21.2959H15.97V18.0959H6.39001Z"
        fill="white"
      />
      <path
        d="M22.36 30.876H25.55V24.486H28.74V21.296H25.55V14.906H31.94V18.096H22.36V30.876Z"
        fill="white"
      />
      <path
        d="M38.32 14.906H35.13V30.876H38.32V14.906Z"
        fill="white"
      />
      <path
        d="M41.52 18.0959V27.6759H54.29V18.0959H51.1V30.8759H44.71V18.0959H41.52Z"
        fill="white"
      />
      </svg>
    </IconWrapper>
  )
}

export const SearchIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
    <path
      className='a'
      fill='#ffca55'
      d='M22.87,33.66a10.73,10.73,0,0,0,6.49-2.18l7.26,7.28a1.37,1.37,0,0,0,1,.38,1.3,1.3,0,0,0,1.32-1.28v-.07a1.3,1.3,0,0,0-.37-.95l-7.23-7.23a10.69,10.69,0,0,0,2.38-6.75,10.81,10.81,0,1,0-10.8,10.8Zm0-1.81a9,9,0,1,1,9-9A9,9,0,0,1,22.87,31.85Z'
    />
    <path
      className='b'
      fill='#ff726f'
      d='M21.91,32.46a10.66,10.66,0,0,0,6.49-2.18l7.27,7.28a1.34,1.34,0,0,0,2.27-.9v-.07a1.32,1.32,0,0,0-.38-.94l-7.23-7.24a10.63,10.63,0,0,0,2.38-6.75,10.8,10.8,0,1,0-10.8,10.8Zm0-1.81a9,9,0,1,1,9-9A9,9,0,0,1,21.91,30.65Z'
    />
  </svg>
)

export const EyeIcon = ({ height = '20px', width = '20px', ...props }) => (
  <IconsCommonContainer {...props}>
    <svg
      height={height}
      width={width}
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <path
        fill='#3E4860'
        d='M2.92,12c0-1.35,3.7-5.74,9.08-5.74s9.08,4.39,9.08,5.74S17.46,17.74,12,17.74,2.92,13.34,2.92,12Zm16.34,0c0-.76-3.19-4.27-7.26-4.27S4.74,11.24,4.74,12,7.91,16.27,12,16.27,19.26,12.9,19.26,12ZM8.58,12a3.42,3.42,0,0,1,6.84,0,3.42,3.42,0,0,1-6.84,0Zm4.5,0A1.08,1.08,0,1,0,12,13.07,1.07,1.07,0,0,0,13.08,12Z'
      />
    </svg>
  </IconsCommonContainer>
)

export const EyeSlashedIcon = ({ height = '20px', width = '20px', ...props }) => (
  <IconsCommonContainer {...props}>
    <svg
      height={height}
      width={width}
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <path
        fill='#3E4860'
        d='M2.92,12.11c0-.79,1.24-2.59,3.35-4L7.43,9.3c-1.67,1-2.69,2.38-2.69,2.81,0,.9,3.17,4.27,7.26,4.27a7.49,7.49,0,0,0,2.09-.31l1.22,1.23a10.21,10.21,0,0,1-3.31.55C6.61,17.85,2.92,13.45,2.92,12.11ZM6,6.74A.56.56,0,0,1,6,6a.54.54,0,0,1,.76,0L18,17.26A.53.53,0,0,1,18,18a.54.54,0,0,1-.77,0Zm2.62,5.37a3.06,3.06,0,0,1,.27-1.26l4.39,4.39a2.88,2.88,0,0,1-1.24.27A3.42,3.42,0,0,1,8.58,12.11Zm12.5,0c0,.77-1.19,2.54-3.24,3.9l-1.13-1.13c1.57-1,2.55-2.27,2.55-2.77,0-.77-3.18-4.27-7.26-4.27a7.64,7.64,0,0,0-1.94.26L8.84,6.88A10,10,0,0,1,12,6.37C17.46,6.37,21.08,10.75,21.08,12.11ZM10.89,8.91A2.89,2.89,0,0,1,12,8.7a3.4,3.4,0,0,1,3.42,3.4,2.69,2.69,0,0,1-.23,1.11Z'
      />
    </svg>
  </IconsCommonContainer>
)

export const ChevronArrowIcon = ({ height = '35px', width = '35px', ...props }) => (
  <IconWrapper {...props}>
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      height={height}
      width={width}
      viewBox='0 0 24 24'
    >
      <path d='M11.12,7.49a1.15,1.15,0,0,1,.88-.4,1.21,1.21,0,0,1,.89.4L20.2,15a1.1,1.1,0,0,1,.33.79,1.14,1.14,0,0,1-2,.8L12,9.82,5.43,16.56a1.14,1.14,0,0,1-.82.35,1.13,1.13,0,0,1-1.14-1.15A1.11,1.11,0,0,1,3.8,15Z' />
    </svg>
  </IconWrapper>
)

export const QuestionIcon = ({ height = '35px', width = '35px', ...props }) => (
  <IconWrapper {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={height}
      width={width}
      viewBox='0 0 50 50'
    >
      <path
        className='a'
        d='M24.16,30.53a2.11,2.11,0,0,0,2.28-1.8c0-.1,0-.25,0-.35.19-1.5.83-2.17,3.28-3.69,2.68-1.67,4.09-3.58,4.09-6.35,0-4.54-3.71-7.31-8.95-7.31-3.89,0-7,1.6-8.24,4.13a4.57,4.57,0,0,0-.52,2.11,2.16,2.16,0,0,0,2,2.29,1.71,1.71,0,0,0,.32,0A2.53,2.53,0,0,0,20.92,18a3.64,3.64,0,0,1,3.74-2.73c2.09,0,3.72,1.26,3.72,3.14s-.95,2.71-3.24,4.15c-2.08,1.33-3.46,2.8-3.46,5v.51a2.31,2.31,0,0,0,2.18,2.42ZM24.22,39a3,3,0,0,0,3-2.9v0a3,3,0,0,0-3-2.93h0a3,3,0,0,0-3,2.92h0a3,3,0,0,0,3,2.92Z'
      />
    </svg>
  </IconWrapper>
)

export const InstagramIcon = ({
  height = '35px',
  width = '35px',
  fillColor = '#FF726F',
  ...props
}) => (
  <IconWrapper fillColor={fillColor} {...props}>
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height={height}
      width={width}
    >
      <path
        id='Icon_awesome-instagram'
        data-name='Icon awesome-instagram'
        d='M12,7.26A4.74,4.74,0,1,0,16.74,12,4.72,4.72,0,0,0,12,7.26Zm0,7.82A3.08,3.08,0,1,1,15.08,12h0A3.08,3.08,0,0,1,12,15.08Zm6-8A1.1,1.1,0,1,1,16.94,6,1.1,1.1,0,0,1,18,7.07Zm3.14,1.12a5.48,5.48,0,0,0-1.49-3.87,5.52,5.52,0,0,0-3.88-1.49c-1.52-.09-6.09-.09-7.62,0A5.49,5.49,0,0,0,4.32,4.31a5.53,5.53,0,0,0-1.5,3.88c-.08,1.52-.08,6.09,0,7.62a5.45,5.45,0,0,0,1.5,3.87,5.48,5.48,0,0,0,3.87,1.49c1.52.09,6.1.09,7.62,0a5.44,5.44,0,0,0,3.87-1.49,5.51,5.51,0,0,0,1.5-3.87c.08-1.53.08-6.09,0-7.62Zm-2,9.25a3.14,3.14,0,0,1-1.76,1.76,20.33,20.33,0,0,1-5.45.37,20.7,20.7,0,0,1-5.44-.37A3.12,3.12,0,0,1,4.8,17.45,20.35,20.35,0,0,1,4.43,12,20.77,20.77,0,0,1,4.8,6.55,3.09,3.09,0,0,1,6.56,4.8,20.33,20.33,0,0,1,12,4.43a20.28,20.28,0,0,1,5.44.37,3.09,3.09,0,0,1,1.76,1.75A20.35,20.35,0,0,1,19.58,12a20.35,20.35,0,0,1-.37,5.45Z'
      />
    </svg>
  </IconWrapper>
)

export const TiktokIcon = ({
  height = '35px',
  width = '35px',
  fillColor = '#4ad1db',
  ...props
}) => (
  <IconWrapper fillColor={fillColor} {...props}>
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height={height}
      width={width}
    >
      <path
        id='Icon_simple-tiktok'
        data-name='Icon simple-tiktok'
        d='M12.38,3.28h2.85a4.52,4.52,0,0,0,1.27,3,5.16,5.16,0,0,0,3.09,1.31v2.93a7.87,7.87,0,0,1-3.06-.71,9.17,9.17,0,0,1-1.18-.67c0,2.12,0,4.25,0,6.37a5.56,5.56,0,0,1-1,2.87,5.41,5.41,0,0,1-4.3,2.33,5.35,5.35,0,0,1-3-.75,5.51,5.51,0,0,1-2.66-4.16c0-.36,0-.73,0-1.08a5.49,5.49,0,0,1,6-4.91l.35.05c0,1.08,0,2.15,0,3.23a2.5,2.5,0,0,0-3.19,1.52v0a3.12,3.12,0,0,0-.1,1.18,2.49,2.49,0,0,0,4.57.91,1.62,1.62,0,0,0,.3-.77c.07-1.3,0-2.6,0-3.9,0-2.93,0-5.86,0-8.79Z'
      />
    </svg>
  </IconWrapper>
)

export const CrossIcon = () => {
  return (
    <svg
      className="icon__cross"
      xmlns='http://www.w3.org/2000/svg'
      width='13.787'
      height='13.791'
      viewBox='0 0 13.787 13.791'
    >
      <path
        id='Path_771'
        data-name='Path 771'
        d='M-6.537-1.3A1.348,1.348,0,0,0-6.52.54,1.339,1.339,0,0,0-4.677.556L0-4.125,4.677.548A1.305,1.305,0,0,0,6.512.531,1.313,1.313,0,0,0,6.529-1.3L1.855-5.977l4.673-4.682a1.313,1.313,0,0,0-.017-1.834,1.313,1.313,0,0,0-1.834-.017L0-7.836l-4.682-4.682A1.348,1.348,0,0,0-6.52-12.5a1.356,1.356,0,0,0-.017,1.843l4.682,4.682Z'
        transform='translate(6.893 12.875)'
        fill='#adadad'
      />
    </svg>
  )
}

export const BurgerMenuIcon = ({ width = "25px", height = "25px", color = "#FFFFFF" }) => {
  return (
    <svg height={height} width={width} viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
        fill={color}
      />
      <path
        d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
        fill={color}
      />
      <path
        d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
        fill={color}
      />
    </svg>
  )
}

export const ArrowInCircleIcon = ({
  color = '#784E4E',
  isGradient = false,
  gradientColor1 = '#AE8585',
  gradientColor2 = '#784E4E',
}) => {
  return (
    <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 49.8604C37.9033 49.8604 48.9873 38.7764 48.9873 25.373C48.9873 11.9453 37.9033 0.885742 24.4756 0.885742C11.0723 0.885742 0.0126953 11.9453 0.0126953 25.373C0.0126953 38.7764 11.0967 49.8604 24.5 49.8604ZM24.5 46.6865C12.6836 46.6865 3.18652 37.165 3.18652 25.373C3.18652 13.5566 12.6836 4.03516 24.4756 4.03516C36.292 4.03516 45.8135 13.5566 45.8379 25.373C45.8379 37.165 36.3164 46.6865 24.5 46.6865ZM24.5 37.5068C25.3545 37.5068 25.9404 36.9209 25.9404 36.0908V21.9307L25.7939 17.3896L28.5039 20.417L32.0195 23.957C32.2881 24.25 32.6055 24.3721 33.0205 24.3721C33.8018 24.3721 34.4121 23.7861 34.4121 23.0293C34.4121 22.6387 34.2656 22.2969 34.0215 22.0527L25.5986 13.6543C25.2568 13.3125 24.915 13.1172 24.5 13.1172C24.1094 13.1172 23.792 13.2881 23.4258 13.6543L15.0029 22.0527C14.7588 22.2969 14.6367 22.6387 14.6367 23.0293C14.6367 23.7861 15.2227 24.3721 16.0039 24.3721C16.4189 24.3721 16.7363 24.2256 17.0049 23.957L20.5205 20.417L23.2061 17.4141L23.084 21.9307V36.0908C23.084 36.9209 23.6699 37.5068 24.5 37.5068Z"
        fill={isGradient ? "url(#icon__arrow-in-circle-gradient)" : color}
      />
      <defs>
        {isGradient && (
          <linearGradient
            id="icon__arrow-in-circle-gradient"
            x1="0.182746"
            y1="39.1007"
            x2="48.9873"
            y2="39.1007"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={gradientColor1} />
            <stop offset="1" stopColor={gradientColor2} />
          </linearGradient>
        )}
      </defs>
    </svg>
  )
}
