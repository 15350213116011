import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import HeaderContainer from '@containers/HeaderContainer'
import FooterContainer from '@containers/FooterContainer'
import CookiesConsent from '@common/CookiesConsent'

import { SCROLL_CONTAINER_ID } from '@constants/settings'

const MainLayoutWrapper = styled.div`
  height: 100vh;
  ${props => props.theme.flex.column};
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;
  ${props => props.theme.scrollStyle}

  ${props =>
    props.withReportGradient &&
    `
    background: ${props.theme.bgGradientReport};
    
  `}

  ${props => props.theme.media.lessThan('mid')`
      ${props.withReportGradient &&
        `
        background: ${props.theme.bgGradientMobileReport};
        
      `}
  `}

  ${props =>
    props.withRankingsGradient &&
    `
    background: ${props.theme.bgGradientRankingsPage};
  `}
  ${props => props.theme.media.lessThan('mid')`
      ${props.withRankingsGradient &&
        `
        background: ${props.theme.bgGradientTabletRankingsPage};
        
      `}
  `}
  ${props => props.theme.media.lessThan('mobile')`
  ${props.withRankingsGradient &&
    `
    background: ${props.theme.bgGradientMobileRankingsPage};
    
  `}
`}
`

const LandingLayout = ({ children, withReportGradient, withRankingsGradient }) => (
  <>
    <MainLayoutWrapper
      withReportGradient={withReportGradient}
      withRankingsGradient={withRankingsGradient}
      id={SCROLL_CONTAINER_ID}
    >
      <HeaderContainer />
      {children}
      <FooterContainer />
    </MainLayoutWrapper>
    <CookiesConsent />
  </>
)

LandingLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  withReportGradient: PropTypes.bool,
  withRankingsGradient: PropTypes.bool,
}
LandingLayout.defaultProps = {
  withReportGradient: false,
  withRankingsGradient: false,
}

export default LandingLayout
